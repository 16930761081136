import * as microsoftTeams from "@microsoft/teams-js";

export type BuildEnvironment =
  | undefined
  | null
  | ""
  | "undefined"
  | "ringdevelopment"
  | "ringtest"
  | "ringproduction";
export type ExecutionEnvironment = "Development" | "Test" | "Production";
export type UserRole = "Administrator" | "Editor" | "Reader";

export const ENV = process.env.REACT_APP_ENV;

let environment: ExecutionEnvironment;
let apiUrl: string;

switch (ENV as BuildEnvironment) {
  case undefined:
  case null:
  case "":
  case "undefined":
  case "ringdevelopment":
    environment = "Development";
    apiUrl = "https://intraactive-play-functions-dev.azurewebsites.net";
    break;
  case "ringproduction":
    environment = "Production";
    apiUrl = "https://intraactive-play-functions.azurewebsites.net";
    break;
  case "ringtest":
    environment = "Test";
    apiUrl = "https://intraactive-play-functions-test.azurewebsites.net";
    break;
}

export const Environment: ExecutionEnvironment = environment;

export const ApiUrl: string = apiUrl;

export const IsInTeams = (): boolean => {
  // eslint-disable-next-line dot-notation
  const microsoftTeamsLib = microsoftTeams || window["microsoftTeams"];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  if ((window.parent === window.self && (window as any).nativeInterface) ||
    window.navigator.userAgent.includes("Teams/") ||
    window.name === "embedded-page-container" ||
    window.name === "extension-tab-frame") {
    return true;
  }

  return false;
};

export const GetLanguageForLocale = (locale: string): 'Danish' | 'English' | 'Swedish' | 'German' | 'Portuguese' | 'Norwegian' => {
  switch (locale.substring(0, 2)) {
    case 'en':
      return 'English';
    case 'da':
      return 'Danish';
    case 'sv':
      return 'Swedish';
    case 'de':
      return 'German';
    case 'pt':
      return 'Portuguese';
    case 'nb':
      return 'Norwegian';
    default:
      return 'English';
  }
}

export const MaximumTries = 5;
