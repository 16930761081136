import React from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import { useTeamsFx } from "./playAdmin/lib/useTeamsFx";
import Tab from "./Tab";
import "./App.css";

export default function App() {
  const { theme, loading } = useTeamsFx();
  return (
    <Provider
      theme={theme || teamsTheme}
      styles={{
        backgroundColor: "#eeeeee"
      }}>
      <Router>
        <Route exact path="/">
          <Redirect to="/tab" />
        </Route>
        {loading ? (
          <Loader style={{ margin: 100 }} />
        ) : (
          <>
            <Route exact path="/tab" component={Tab} />
          </>
        )}
      </Router>
    </Provider>
  );
}
