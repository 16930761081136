import { useTeamsFx } from "./playAdmin/lib/useTeamsFx";
import { PlayAdmin } from "./playAdmin/PlayAdmin";

// var showFunction = Boolean(process.env.REACT_APP_FUNC_NAME);

export default function Tab() {
  const { themeString } = useTeamsFx();
  return (
    <PlayAdmin
      isDarkMode={themeString === "dark"}
    />
  );
}
