import { ApiUrl } from "./constants";

export function fetchData<T>(url: string, token: string): Promise<T> {
  return fetch(`${ApiUrl}/${url}`, {
    headers: new Headers({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json() as Promise<T>
    });
}